import { Col, Row, Typography } from 'antd';
import Icon from '../atoms/Icon';
import {
    Division,
    Field,
    IncomeCalculation,
    Tints,
} from '../../utils/interfaces/halcyon360';
import CalculatorInputText from '../atoms/CalculatorInputText';
import CalculatorInput from '../atoms/CalculatorInput';
import CalculatorChoicesRow from './CalculatorChoicesRow';

interface CalculatorPrefixParams {
    division: Division;
    field: Field;
    closed: boolean;
    setClosed: (_: boolean) => void;
    nextId: string;
    ancestry: string;
    duplicate: (_ancestry: string, _division: Division, _isRemove: boolean) => void;
    selectOptionalField: (_ancestry: string, _division: Division, _addition: Division, _isChoice: boolean) => void;
    calculation: IncomeCalculation;
    indent: number;
    tints: Tints;
}

const CalculatorPrefix = ({
    division,
    field,
    closed,
    setClosed,
    nextId,
    ancestry,
    duplicate,
    indent,
    tints,
    selectOptionalField,
    calculation,
}: CalculatorPrefixParams) => (closed ? (
    <Row
        gutter={8}
        className={`${(division.children || field.prefix) ? 'pointer ' : ''}pa-2 arow-${indent} analyzer_row${ancestry === 'start' ? ' analyzer_start' : ''}`}
        onClick={(division.children || field.prefix) ? () => { setClosed(!closed); } : undefined}
    >
        <Col xs={16} className="flex-start-start">
            {(division.children || field.prefix) && (
                <Icon
                    name={closed ? 'RightOutlined' : 'DownOutlined'}
                    className={`mt-1 mr-1 ${(ancestry !== 'start') ? 'drop-left' : ''}`}
                />
            )}
            <div>
                <Typography.Title level={5} className="mti-0 mb-0" style={{ textDecoration: field.noCashFlow ? 'line-through' : undefined }}>
                    {field?.prefix?.title || field.title}
                    {field.prefix?.input?.value && (
                        <span style={{ fontSize: 14, fontWeight: 500 }}>
                            {' '}
                            -
                            {' '}
                            {field.prefix.input.value}
                        </span>
                    )}
                </Typography.Title>
            </div>
        </Col>
        <Col xs={8} className="pl-4">
            <Row gutter={8} justify="space-between" align="middle">
                <Col xs={2} />
                {calculation.usedYears.map((v, vIdx) => (
                    <Col
                        key={`${division.id}-${v}-calculation-sm-${vIdx}`}
                        xs={Math.floor(22 / (calculation.usedYears.length || 1))}
                    >
                        <CalculatorInput
                            noEdit
                            location={(`${ancestry}|${division.id}`).split('|')}
                            division={division}
                            valueIndex={vIdx}
                        />
                    </Col>
                ))}
                <Col xs={2} />
            </Row>
        </Col>
    </Row>
) : (
    <Row
        gutter={8}
        className={`${(division.children && !field.prefix) ? 'pointer ' : ''}${ancestry === 'start' ? '' : ''} analyzer_row`}
        onClick={(division.children && !field.prefix) ? () => { setClosed(!closed); } : undefined}
    >
        {(division.children && !field.prefix) && (
            <Icon
                name={closed ? 'RightOutlined' : 'DownOutlined'}
                className="mt-1 mr-1"
            />
        )}
        <div className="w100">
            <Row
                justify="start"
                align="middle"
                className={`${(division.children && field.prefix) ? 'pointer ' : ''}${ancestry === 'start' ? 'analyzer_start' : ''} analyzer_row arow-${ancestry === 'start' ? 0 : indent === 0 ? 1 : indent} pa-2 ${tints[`${division.id}-p`] ? ' lightMediumGray' : ''}`}
                onClick={(division.children && field.prefix) ? () => { setClosed(!closed); } : undefined}
            >
                {(division.children && field.prefix) && (
                    <Icon
                        name={closed ? 'RightOutlined' : 'DownOutlined'}
                        className={`mt-1 mr-1 ${(ancestry !== 'start') ? 'drop-left' : 'ml-1'}`}
                    />
                )}
                <Typography.Title level={5} className="mb-0 mti-0" style={{ textDecoration: field.noCashFlow ? 'line-through' : undefined }}>{field?.prefix?.title}</Typography.Title>
                {((field.multifield && !division.choices?.length) && (nextId !== (division.oId || division.id) || field.isSupplement)) && (
                    <Icon
                        name="PlusCircleOutlined"
                        onClick={() => { duplicate(ancestry, division, false); }}
                        color="#1677FF"
                        className="ml-1"
                    />
                )}
                {(field.isSupplement) && (
                    <Icon
                        name="MinusCircleOutlined"
                        onClick={() => { duplicate(ancestry, division, true); }}
                        color="red"
                        className="ml-1"
                    />
                )}
            </Row>
            {field?.prefix?.input && (
                <div className={`arow-${ancestry === 'start' ? 1 : indent === 0 ? 1 : indent} pa-2 analyzer_row flex-center-start${tints[`${division.id}-i`] ? ' lightMediumGray' : ''}`}>
                    <Typography className="mti-0 mr-1">
                        {field.prefix.input.label}
                        :
                    </Typography>
                    <CalculatorInputText
                        division={division}
                    />
                </div>
            )}
            {division?.choices && (
                <div className={`arow-${ancestry === 'start' ? 1 : indent === 0 ? 1 : indent} pa-2 analyzer_row flex-center-start${tints[`${division.id}-c`] ? ' lightMediumGray' : ''}`}>
                    <CalculatorChoicesRow
                        choices={division.choices}
                        ancestry={ancestry}
                        calculation={calculation}
                        division={division}
                        selectOptionalField={selectOptionalField}
                    />
                </div>
            )}
        </div>
    </Row>
));

export default CalculatorPrefix;
