/* eslint-disable no-param-reassign */
import { Input as AntInput, InputProps as AntInputProps, Typography } from 'antd';
import { useMemo } from 'react';
import _ from 'lodash';
import { NumericFormat } from 'react-number-format';
import { useDispatch, useSelector } from 'react-redux';

import { AppDispatch, RootState } from '../../configureStore';
import { UPDATE_CALCULATION_MONTHS } from '../../redux/reducer/analyzer';
import { Summary } from '../../utils/interfaces/halcyon360';

interface CalculatorSummaryInputParams {
    summaryKey: keyof Summary;
    index?: number;
    disabled?: boolean;
    flow?: boolean;
}

interface InputProps extends AntInputProps {
    noEdit?: boolean;
}

const Input = (props: InputProps) => (
    <AntInput
        disabled={props.noEdit}
        size="small"
        value={props.value}
        {...props}
    />
);

const CalculatorSummaryInput = ({
    summaryKey,
    index,
    disabled = false,
    flow = false,
    ...props
}: CalculatorSummaryInputParams) => {
    const dispatch = useDispatch<AppDispatch>();
    const value = useSelector<RootState, string | number>(({ analyzer: { calculation, activeBorrower } }) => {
        const year = calculation.data[activeBorrower || ''].usedYears[index || 0];
        const summary = calculation.data[activeBorrower || '']?.summary;
        const val: string | number | { [year: string]: number; } = summary[summaryKey] || '0';
        if (typeof val === 'number' || typeof val === 'string') return val;
        return val[year];
    });

    const isNeg = useMemo<boolean>(() => (value ? String(value).includes('-') : false), [value]);

    return flow ? (
        <Typography>{value}</Typography>
    ) : (
        <NumericFormat
            thousandSeparator={summaryKey !== 'months'}
            className={`${summaryKey !== 'months' ? `${isNeg ? '' : 'enabled '}notscript ` : ''}${isNeg ? 'danger--text_enabled' : ''}`}
            customInput={Input}
            onChange={(e) => {
                if (summaryKey === 'months') {
                    dispatch({
                        type: UPDATE_CALCULATION_MONTHS,
                        newValue: Number(e.currentTarget.value || 1),
                    });
                }
            }}
            prefix={summaryKey === 'months' ? undefined : '$'}
            value={value}
            disabled={summaryKey !== 'months' || disabled}
            {...props}
        />
    );
};

export default CalculatorSummaryInput;
