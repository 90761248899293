import {
    Card,
    Col,
    Row,
    Typography,
} from 'antd';
import { Summary as ISummary } from '../../../utils/interfaces/halcyon360';
import CalculatorSummaryInput from '../../atoms/CalculatorSummaryInput';
import { formatDollar } from '../../../utils/helpers';

interface SummaryParams {
    summary: ISummary;
    usedYears: string[];
    className?: string;
    name?: string;
    flow?: boolean;
}

const Summary = ({
    summary,
    usedYears,
    className,
    name,
    flow = false,
}: SummaryParams) => (
    <Card
        className={className}
        classNames={{
            header: 'gray_bg',
            body: 'lighterGray',
        }}
        style={{ textAlign: 'left' }}
        styles={{
            body: {
                paddingTop: 8,
                paddingBottom: 8,
            },
        }}
        title={name ?? 'Total'}
    >
        <Row gutter={8} className="py-1">
            <Col xs={16}>
                <Typography className="mb-1 mti-0">
                    Sum of combined subtotals
                </Typography>
            </Col>
            <Col xs={8}>
                <Row gutter={8} justify="space-between">
                    <Col xs={2} />
                    {usedYears.map((y, idx) => (
                        <Col
                            key={`summary-sub-${y}-${idx}`}
                            xs={Math.floor(22 / (usedYears.length || 1))}
                            className={summary.totals[y] < 0 ? 'danger--text' : ''}
                        >
                            {formatDollar(summary.totals[y])}
                        </Col>
                    ))}
                </Row>
            </Col>
        </Row>
        <Row gutter={8} className="py-1">
            <Col xs={16}>
                <Typography className="mb-1 mti-0">
                    Total income used to determine stable monthly income
                </Typography>
            </Col>
            <Col xs={8}>
                <Row gutter={8} justify="space-between">
                    <Col xs={2} />
                    {usedYears.map((y, idx) => (
                        <Col
                            key={`summary-total-${y}-${idx}`}
                            xs={Math.floor(22 / (usedYears.length || 1))}
                            className={summary.income < 0 ? 'danger--text' : ''}
                        >
                            {!idx && formatDollar(summary.total)}
                        </Col>
                    ))}
                </Row>
            </Col>
        </Row>
        <Row gutter={8} className="py-1">
            <Col xs={16}>
                <Typography className="mb-1 mti-0">
                    Divided by
                    {' '}
                    {summary.months}
                    {' '}
                    months
                </Typography>
            </Col>
            <Col xs={8}>
                <Row gutter={8} justify="space-between">
                    <Col xs={2} />
                    {usedYears.map((y, idx) => (
                        <Col
                            key={`summary-months-${y}-${idx}`}
                            xs={Math.floor(22 / (usedYears.length || 1))}
                        >
                            {!idx && (
                                <CalculatorSummaryInput
                                    summaryKey="months"
                                    disabled={Boolean(name)}
                                    flow={flow}
                                />
                            )}
                        </Col>
                    ))}
                </Row>
            </Col>
        </Row>
        <Row gutter={8} className="py-1">
            <Col xs={16}>
                <Typography.Title level={5} className="mb-1 mti-0">Total stable monthly income</Typography.Title>
            </Col>
            <Col xs={8}>
                <Row gutter={8} justify="space-between">
                    <Col xs={2} />
                    {usedYears.map((y, idx) => (
                        <Col
                            key={`summary-income-total-${y}-${idx}`}
                            xs={Math.floor(22 / (usedYears.length || 1))}
                        >
                            <Typography.Title
                                level={5}
                                className={summary.income < 0 ? 'danger--text' : ''}
                            >
                                {!idx && formatDollar(summary.income)}
                            </Typography.Title>
                        </Col>
                    ))}
                </Row>
            </Col>
        </Row>
    </Card>
);

export default Summary;
