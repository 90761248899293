/* eslint-disable no-param-reassign */
import {
    Input as AntInput,
    InputProps as AntInputProps,
    Col,
    Row,
    Tooltip,
} from 'antd';
import _ from 'lodash';
import { useMemo } from 'react';
import { NumericFormat } from 'react-number-format';
import { useDispatch, useSelector } from 'react-redux';

import { AppDispatch, RootState } from '../../configureStore';
import { FLAG_MODAL_OPEN, UPDATE_CALCULATION } from '../../redux/reducer/analyzer';
import {
    Division,
    Field,
    Flag,
    Operation,
} from '../../utils/interfaces/halcyon360';
import Icon from './Icon';
import { formatDollar } from '../../utils/helpers';

interface CalculatorInputParams {
    noEdit?: boolean;
    location: string[];
    valueIndex: number;
    isSubtotal?: boolean;
    isFactor?: boolean;
    division: Division;
}

interface InputProps extends AntInputProps {
    noEdit?: boolean;
    onClear: () => void;
    operation?: Operation;
    onFlagged?: () => void;
}

const Input = ({
    onClear: _onClear,
    operation,
    ...props
}: InputProps) => {
    const isNeg = useMemo<boolean>(() => (props.value ? String(props.value).includes('-') : false), [props.value]);
    const className = useMemo<string | undefined>(() => {
        const base = (props.className || '') + ((isNeg || operation === 'subtract') ? ' danger--text' : '');
        if (isNeg && base.includes('enabled')) return base.replace('enabled', 'enabled-bg');
        return base;
    }, [isNeg, props.className]);

    return (
        <AntInput
            disabled={props.noEdit}
            size="small"
            value={props.value}
            {...props}
            className={className}
        />
    );
};

const CalculatorInput = ({
    isSubtotal,
    isFactor,
    noEdit,
    location,
    valueIndex,
    division,
    ...props
}: CalculatorInputParams) => {
    const dispatch = useDispatch<AppDispatch>();
    const field = useSelector<RootState, Field>(({ analyzer: { activeBorrower, calculation } }) => calculation.data[activeBorrower || ''].fields[division.id]);
    const usedYears = useSelector<RootState, string[]>(({ analyzer: { activeBorrower, calculation } }) => calculation.data[activeBorrower || ''].usedYears);
    const year = useMemo<string>(() => usedYears[valueIndex], []);
    const flag = useMemo<Flag | undefined>(() => field?.values?.[year]?.flag?.find(({ active }) => active), [year, field]);
    const hasFlag = useMemo<boolean>(() => Boolean(field?.values?.[year]?.flag?.length), [year, field]);
    const { value, override } = useMemo<{ value?: number; override: boolean; }>(() => {
        const val = isFactor
            ? (field.subtotal?.factor?.[year]?.override ?? field.subtotal?.factor?.[year]?.value ?? undefined)
            : isSubtotal
                ? (field.subtotal?.values?.[year]?.override ?? field.subtotal?.values?.[year]?.value ?? undefined)
                : (field.values?.[year]?.override ?? field.values?.[year]?.value ?? undefined);
        return {
            value: val !== undefined ? val * (flag?.multiplier ?? 1) : val,
            override: Boolean(isFactor ? field.subtotal?.factor?.[year]?.override : isSubtotal ? field.subtotal?.values?.[year]?.override : field.values?.[year]?.override),
        };
    }, [field, flag]);

    const onFlagged = () => {
        dispatch({
            type: FLAG_MODAL_OPEN,
            fieldId: division.id,
            year,
            location,
        });
    };

    const className = useMemo(() => (`${flag ? 'flag' : ''} ${(noEdit && (!isFactor || Boolean(field.subtotal?.factorValue))) ? 'enabled' : ''} ${(field.path?.length || (division.children?.length && field.operation !== 'multi')) ? '' : 'notscript'} `.trim()), [flag, field, division]);

    return noEdit ? value ? (
        <div
            className={`ml-2 ${(value < 0 || field.operation === 'subtract') ? 'danger--text' : ''}`}
            style={{ textDecoration: field.noCashFlow ? 'line-through' : undefined }}
        >
            {(!isFactor && !field.notDollar) ? formatDollar(value) : value}
        </div>
    ) : <div /> : (
        <Row gutter={2} justify="start" align="middle">
            <Col span={22}>
                {override && (
                    <Tooltip
                        placement="top"
                        title="This field has been manually edited"
                    >
                        <div className="has_override" />
                    </Tooltip>
                )}
                <NumericFormat
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                        const neg = e.target.value.includes('-');
                        const str = isFactor ? e.target.value.replace(/[^0-9.]/g, '') : e.target.value.replace(/\D/g, '');
                        const newValue = (Number.isNaN(Number(str)) ? 0 : Number(str) * (neg ? -1 : 1));
                        dispatch({
                            type: UPDATE_CALCULATION,
                            location,
                            newValue,
                            valueIndex,
                            isFactor,
                            year,
                            division,
                        });
                    }}
                    onClear={() => {
                        dispatch({
                            type: UPDATE_CALCULATION,
                            location,
                            newValue: value,
                            valueIndex,
                            removeOverride: true,
                            isFactor,
                            year,
                            division,
                        });
                    }}
                    thousandSeparator={!isFactor && !field.notDollar}
                    className={className}
                    customInput={Input}
                    max={isFactor ? 100 : undefined}
                    min={isFactor ? 0 : undefined}
                    prefix={(isFactor && !field.subtotal?.isCents) || field.notDollar ? undefined : '$'}
                    value={value}
                    disabled={(noEdit && (!isFactor || Boolean(field.subtotal?.fixedFactor)))}
                    operation={field.operation}
                    {...props}
                />
            </Col>
            <Col span={2}>
                {hasFlag && (
                    <Icon
                        color="#FAAD14"
                        name="BulbOutlined"
                        onClick={() => { onFlagged(); }}
                    />
                )}
            </Col>
        </Row>
    );
};

export default CalculatorInput;
