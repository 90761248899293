import { useMemo } from 'react';
import { Modal, Table, Typography } from 'antd';
import { useSelector } from 'react-redux';
import { ColumnsType } from 'antd/lib/table';

import { RootState } from '../../configureStore';
import {
    AuditEvent,
    AuditType,
    Fields,
    FlagActiveEvent,
    YearCountEvent,
} from '../../utils/interfaces/halcyon360';
import { formatDollar } from '../../utils/helpers';

interface ReviewChangesModalParams {
    id: string | null;
    onClose: () => void;
    divisionId?: string;
    fieldIds?: string[];
    fieldType?: 'subtotal' | 'factor';
}

const ReviewChangesModal = ({
    id,
    onClose,
    divisionId,
    fieldIds,
    fieldType,
}: ReviewChangesModalParams) => {
    const ab = useSelector<RootState, string>(({ analyzer: { activeBorrower } }) => activeBorrower || '');
    const auditLog = useSelector<RootState, AuditEvent[]>(({ analyzer: { calculation: { auditLog: al } } }) => al);

    const changes = useMemo<AuditEvent[]>(() => (
        (!divisionId ? auditLog.filter(({ changeId }) => changeId === id) : auditLog.filter(({ iii, fieldId }) => (iii === ab && ((fieldType !== 'subtotal' && fieldId === divisionId) || (fieldIds || [])?.includes(fieldId)))))
    ), [
        auditLog,
        id,
        ab,
        fieldIds,
        fieldType,
        divisionId,
    ]);

    const notes = useSelector<RootState, string | undefined>(({ analyzer: { calculation: { history } } }) => history.find(({ calculatorId }) => calculatorId === id)?.notes);
    const fields = useSelector<RootState, Fields>(({ analyzer: { calculation: c } }) => c.data?.[ab || '']?.fields || {});
    const createdBy = useSelector<RootState, string>(({ analyzer: { calculation: { history } } }) => history.find(({ calculatorId }) => calculatorId === id)?.createdBy || '');

    const columns = useMemo<ColumnsType<AuditEvent>>(() => ([
        {
            dataIndex: 'fieldId',
            title: 'Field',
            ellipsis: true,
            render: (fieldId: string, { type }) => (type === 'factor' ? (fields?.[fieldId.substring(0, 36)]?.subtotal?.multiplyTitle || '-') : (fields?.[fieldId.substring(0, 36)]?.title || '-')),
        },
        {
            title: 'Event',
            dataIndex: 'type',
            render: (val: AuditType, record: AuditEvent) => {
                switch (val) {
                    case 'factor':
                        return 'Factor value updated';
                    case 'created':
                        return 'Analyzer created';
                    case 'forms':
                        return 'Forms were added';
                    case 'lock':
                        return 'File Lock';
                    case 'override':
                        return 'Value Updated';
                    case 'year':
                        return `${record.fieldId.endsWith('0') ? 'First' : 'Second'} Year Changed`;
                    case 'name':
                        return 'Name Added';
                    case 'field-add':
                        return 'Field Added';
                    case 'field-remove':
                        return 'Field Removed';
                    case 'filename':
                        return 'File Renamed';
                    case 'yearcount':
                        return (record as unknown as YearCountEvent).oldValue > (record as unknown as YearCountEvent).value ? 'Removed Year' : 'Added Year';
                    case 'flag-multiplier':
                        return 'Notice factor changed';
                    case 'cashflow':
                        return record.value ? 'Income included' : 'Income excluded';
                    case 'flag-active':
                        return `Notice factor ${(record as unknown as FlagActiveEvent).value ? 'added' : 'removed'}`;
                    case 'business':
                    default: return val || '-';
                }
            },
        },
        {
            title: 'Year',
            dataIndex: 'year',
            render: (a) => a || '-',
        },
        {
            dataIndex: 'oldValue',
            title: 'Previous Value',
            render: (val: string | number, record: AuditEvent) => (
                record.type === 'override'
                    ? Number.isNaN(Number(val)) ? '-' : formatDollar(Number(val))
                    : record.type === 'factor'
                        ? Number.isNaN(Number(val)) ? '-' : `${val}%`
                        : record.type === 'lock'
                            ? val ? 'Locked' : 'Unlocked'
                            : record.type === 'flag-active' ? val ? 'Active' : 'Inactive'
                                : record.type === 'yearcount' ? `${val} Year${val === 1 ? '' : 's'}`
                                    // eslint-disable-next-line eqeqeq
                                    : val == undefined ? '-' : val
            ),
        },
        {
            dataIndex: 'value',
            title: 'New Value',
            render: (val: string | number, { type }) => (
                type === 'override'
                    ? Number.isNaN(Number(val)) ? 'N/A' : formatDollar(Number(val))
                    : type === 'factor'
                        ? Number.isNaN(Number(val)) ? 'N/A' : `${val}%`
                        : type === 'lock'
                            ? val ? 'Locked' : 'Unlocked'
                            : type === 'flag-active' ? val ? 'Active' : 'Inactive'
                                : type === 'yearcount' ? `${val} Year${val === 1 ? '' : 's'}`
                                    // eslint-disable-next-line eqeqeq
                                    : val == undefined ? '-' : val
            ),

        },
    ]), []);

    return (
        <Modal
            title="Review Changes"
            open={Boolean(id)}
            width="90%"
            onCancel={onClose}
            onOk={onClose}
            cancelText="Close"
        >
            <Typography className="my-3">
                Created By:
                {' '}
                {createdBy}
            </Typography>
            <Table
                rowKey={({ changeId, fieldId, year, iii, value }) => `${changeId}_${fieldId}_${year}_${iii}_${value}_`}
                dataSource={changes}
                columns={columns}
            />
            <div className="lightMediumGray br-sm pa-2">
                <Typography.Title level={5}>Notes:</Typography.Title>
                <Typography>{notes}</Typography>
            </div>
        </Modal>
    );
};

export default ReviewChangesModal;
