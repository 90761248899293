import {
    Button,
    Checkbox,
    Col,
    Row,
    Typography,
} from "antd";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import Summary from "./Analyzer/Summary";
import { formatDollar } from "../../utils/helpers";
import { AppDispatch, RootState } from "../../configureStore";
import { CHANGE_IN_FLOW_USE, UPDATE_CASHFLOW } from "../../redux/reducer/analyzer";
import { Calculation, IncomeCalculation } from "../../utils/interfaces/halcyon360";
import LoadingOverlay from "../atoms/LoadingOverlay";

interface CashFlowParams {
    setOpen: React.Dispatch<React.SetStateAction<boolean>>;
    changedIds: { changedValue: boolean; parentIdx: number; id: string; }[];
    setChangedIds: React.Dispatch<React.SetStateAction<{ changedValue: boolean; parentIdx: number; id: string; }[]>>;
}

const CashFlow = ({
    setOpen,
    changedIds,
    setChangedIds,
}: CashFlowParams) => {
    const dispatch = useDispatch<AppDispatch>();

    const calculation = useSelector<RootState, IncomeCalculation>(({ analyzer: { calculation: c, activeBorrower: ab } }) => c.data[ab || '']);
    const fullCalc = useSelector<RootState, Calculation>(({ analyzer: { calculation: c } }) => c);

    useEffect(() => {
        dispatch({ type: UPDATE_CASHFLOW });
    }, []);

    return calculation.cashFlow ? (
        <div>
            <Row
                className="lightMediumGray analyzer_row pa-2 my-2"
                gutter={8}
            >
                <Col span={16}><Typography.Title level={5} className="mb-0">{fullCalc.type}</Typography.Title></Col>
                <Col span={8}>
                    <Row gutter={8}>
                        {calculation.usedYears.map((uy, utIdx) => (
                            <Col key={`cf-uy-${utIdx}`} span={24 / calculation.usedYears.length}>{uy}</Col>
                        ))}
                    </Row>
                </Col>
            </Row>
            {calculation.cashFlow.divisions.map(({ children, id }, pIdx) => (
                <div key={`cash-flow-division-${id}`}>
                    <Row
                        gutter={8}
                        className="pa-2 mb-1 mt-2 analyzer_row analyzer_start"
                    >
                        {calculation.fields[id].prefix?.title}
                    </Row>
                    {(children ?? []).map((child) => (
                        <Row
                            gutter={8}
                            className="pa-2 my-1 analyzer_row"
                            style={{ borderBottom: '1px solid #F0F2F5' }}
                        >
                            <Col span={1} style={{ whiteSpace: 'nowrap', overflowX: 'clip', textOverflow: 'ellipsis' }}>
                                <Checkbox
                                    onChange={(e) => {
                                        dispatch({ type: CHANGE_IN_FLOW_USE, checked: e.target.checked, parentIdx: pIdx, id: child.id });
                                    }}
                                    checked={calculation.fields[child.id].cashFlow?.inUse}
                                />
                            </Col>
                            <Col span={15} style={{ whiteSpace: 'nowrap', overflowX: 'clip', textOverflow: 'ellipsis' }}>
                                {calculation.fields[child.id].cashFlow?.title}
                            </Col>
                            <Col span={8}>
                                <Row gutter={8}>
                                    {calculation.usedYears.map((uy, utIdx) => (
                                        <Col
                                            key={`cf-uy-${utIdx}`}
                                            span={24 / calculation.usedYears.length}
                                            style={{ textDecoration: !calculation.fields[child.id].cashFlow?.inUse ? 'line-through' : undefined }}
                                            className={Number(calculation.fields[child.id].cashFlow?.totals?.[uy]?.override ?? calculation.fields[child.id].cashFlow?.totals?.[uy]?.value) < 0 ? 'danger--text' : ''}
                                        >
                                            {formatDollar(Number(calculation.fields[child.id].cashFlow?.totals?.[uy]?.override ?? calculation.fields[child.id].cashFlow?.totals?.[uy]?.value))}
                                        </Col>
                                    ))}
                                </Row>
                            </Col>
                        </Row>
                    ))}
                </div>
            ))}
            <Summary
                className="my-4"
                summary={calculation.cashFlow.summary}
                usedYears={calculation.usedYears}
                flow
            />
            <Row justify="end" gutter={8} align="middle">
                <Col>
                    <Button
                        onClick={() => {
                            changedIds.forEach((chn) => {
                                dispatch({ type: CHANGE_IN_FLOW_USE, checked: !chn.changedValue, parentIdx: chn.parentIdx, id: chn.id });
                            });
                            setOpen(false);
                        }}
                    >
                        Cancel
                    </Button>
                </Col>
                <Col>
                    <Button
                        type="primary"
                        onClick={() => { setChangedIds([]); setOpen(false); }}
                    >
                        Confirm
                    </Button>
                </Col>
            </Row>
        </div>
    ) : <LoadingOverlay />;
};

export default CashFlow;
